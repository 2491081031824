import Layout from "../components/Base/Layout";
import React from "react";
import { SEO } from "../components/Base/Seo";
import SendPasswordResetEmailForm from "../components/ResetPassword/sendPasswordResetEmailForm";
import UnAuthContent from "../components/Login/UnAuthContent";

const SendPasswordResetEmailPage: React.FC = () => {

    return (
        <Layout>
            <UnAuthContent>
                <SendPasswordResetEmailForm></SendPasswordResetEmailForm>
            </UnAuthContent>
        </Layout>
    );
};


export const Head = () => (
    <SEO title="CDI Health" description="Page description" />
)

export default SendPasswordResetEmailPage;
